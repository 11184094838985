*{
  box-sizing: border-box;
}
*:focus{
  outline: none;
}
a{
  text-decoration: none;
}
a:active,
a:hover {
  outline: 0;
}
small {
  font-size: 80%; 
}
img {
  max-width: 100%;
  border: 0; 
}
address{font-style: normal;}

button,
input,
optgroup,
textarea {
  border:0;
  background: none;
  color: inherit;
  padding: 0;
  font: inherit;
  margin: 0;
  -webkit-appearance: none;
}
textarea {
  overflow: auto;
  resize: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0; 
}
ul,ol{
  margin:0;
  padding-left:0;
  list-style-type: none;
}
td,th {
  padding: 0;
}
p{
  margin:0;
}
input[type=search]::-ms-clear {  display: none; width : 0; height: 0; }
input[type=search]::-ms-reveal {  display: none; width : 0; height: 0; }

/* clears the 'X' from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration { display: none; }

input::-ms-clear { display: none; }

body.ovh {
  overflow: hidden;
}

input[type="date"]::-webkit-inner-spin-button {
  display: none;
  -webkit-appearance: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-transition: "color 9999s ease-out, background-color 9999s ease-out";
  -webkit-transition-delay: 9999s;
}

a:focus,
.button:focus {
  text-decoration: none;
  outline: none;
  box-shadow: none;
}
a,
.button, .btn {
  transition: color 0.35s cubic-bezier(0.51, 0.92, 0.24, 1.15) 0s;
}

select::-ms-expand {
  display: none;
}

.btn:focus,
button:focus {
  box-shadow: none;
  outline: none;
}

input[type="data"]::-webkit-clear-button {
  display: none;
}

a:focus,
a:hover{
  text-decoration: none;
}

*[hidden]{
  display: none!important;
}